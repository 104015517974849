import { unref } from 'vue'
import { get, set, update, del, keys, getMany } from 'idb-keyval'
import { hashKey, onlineManager, QueryClient } from '@tanstack/vue-query'
import { v4 as uuidv4 } from 'uuid'
import deepmerge from 'deepmerge'

import dconsole from '@/shared/plugins/debug_console'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'
import { experimental_createPersister, makePersisterStorageKey } from './createPersister'
import store from '@/store'

const baseUrl = (queryKey) => {
    const config = store.getters.getConfig
    if (queryKey[0] === 'v2' || queryKey[0] === 'valuation' || queryKey[0] === 'payment') {
        return config.VALUATION_API_URL
    } else if (queryKey[0] === 'auth') {
        return config.AUTH_API_URL
    } else {
        return '/'
    }
}

const cleanQueryKey = (queryKey) => {
    return queryKey ? unref(queryKey).map(unref) : []
}

// TODO: return query filter and account for possible params (eg `modified_after`)
const getListQueryKey = (queryKey, verb) => {
    var listQueryKey = cleanQueryKey(queryKey).filter((key) => typeof key !== 'object')

    if (['DELETE'].includes(verb)) listQueryKey = queryKey.slice(0, -1)
    else if (['PATCH', 'PUT'].includes(verb)) {
        if (
            queryKey[1] == 'request' &&
            queryKey.length >= 4 &&
            ['valuer', 'status', 'borrower'].includes(queryKey[3])
        ) {
            // Handle PUT valuation/request/:ref/valuer and PUT valuation/request/:ref/status
            listQueryKey = listQueryKey.slice(0, 2)
        } else {
            listQueryKey = listQueryKey.slice(0, -1)
        }
    }
    return listQueryKey
}

const getBaseQueryKey = (queryKey, verb) => {
    var baseQueryKey = cleanQueryKey(queryKey).filter((key) => typeof key !== 'object')

    if (
        ['PATCH', 'PUT'].includes(verb) &&
        baseQueryKey[1] == 'request' &&
        baseQueryKey.length == 4 &&
        ['valuer', 'status', 'borrower', 'transaction_value'].includes(baseQueryKey[3])
    ) {
        // Handle PUT valuation/request/:ref/valuer and PUT valuation/request/:ref/status
        baseQueryKey = baseQueryKey.slice(0, 3)
    }

    return baseQueryKey
}

const storage = {
    getItem: function (key) {
        dconsole.log(`⬅ 📦 GET from query storage: ${key}`)
        return get(key)
        // return localStorage.getItem(key)
    },
    setItem: function (key, value) {
        dconsole.log(`➡ 📦 SET to query storage: ${key}`)
        return set(key, value)
        // return localStorage.setItem(key, value)
    },
    removeItem: function (key) {
        dconsole.log(`📦 DEL from query storage: ${key}`)
        return del(key)
        // return localStorage.removeItem(key)
    },
    // NOTE: bc get and set are async and non-atomic, there's a risk of race condition
    // when updating an item. Therefore we need to use update instead of set:
    updateItem: function (key, fn) {
        dconsole.log(`🔄 📦 UPDATE in query storage: ${key}`)
        return update(key, fn)
    },
    getKeys: function () {
        return keys()
    },
}

const OPTIMISTIC_MUTATION_PREFIX = 'optimistic'
const CRUD_MUTATION_PREFIX = 'crud'
const OFFLINE_MUTATION_PREFIX = 'offline'
const SUBMIT_STORE_MUTATION_PREFIX = 'submitStoreMutations'

const getMutationStorageKey = ({ queryKey, verb } = { queryKey: null, verb: null }) =>
    makePersisterStorageKey({ queryKey, verb, prefix: 'Mutation' })

/*
██    ██  ██████  ██      ██    ██     ██   ██  █████   ██████ ██   ██
██    ██ ██       ██       ██  ██      ██   ██ ██   ██ ██      ██  ██
██    ██ ██   ███ ██        ████       ███████ ███████ ██      █████
██    ██ ██    ██ ██         ██        ██   ██ ██   ██ ██      ██  ██
 ██████   ██████  ███████    ██        ██   ██ ██   ██  ██████ ██   ██
*/

// NOTE: until the backend fixes it, we need to modify documents endpoint to make them CRUD-compatible
// TODO: fix in backend and remove this
const patchDocumentEndpoint = (verb, queryKey) => {
    // Skip if not a document endpoint or a GET INDEX request or a POST
    if (
        queryKey.includes('documents') &&
        (['PATCH', 'PUT', 'DELETE'].includes(verb) || (verb === 'GET' && queryKey.at(-1) !== 'documents'))
    ) {
        var idx = queryKey.indexOf('documents')
        if (idx > -1) {
            queryKey[idx] = 'document'
        }
        idx = queryKey.indexOf('request')
        if (idx > -1) {
            queryKey.splice(idx, 2)
        }
    }

    return queryKey
}

/*
 ██████  ██    ██ ███████ ██████  ██    ██
██    ██ ██    ██ ██      ██   ██  ██  ██
██    ██ ██    ██ █████   ██████    ████
██ ▄▄ ██ ██    ██ ██      ██   ██    ██
 ██████   ██████  ███████ ██   ██    ██
    ▀▀
*/

const defaultQueryFn = async ({ queryKey }) => {
    // Automatically run GET from queryKey-based URL

    queryKey = cleanQueryKey(queryKey)

    // if (!onlineManager.isOnline()) {
    //     // TODO: check what happens in real offline mode if we donc catch this
    //     dconsole.log('🔎 defaultQueryFn: Simulating offline mode')
    //     throw new Error('Offline mode')
    // }

    // TODO: move conversion of queryKey to a utils function and make more robust:
    var pathSegments = queryKey.filter(
        (key) => typeof key !== 'object' && key !== 'valuation' && key !== 'auth'
    )
    pathSegments = patchDocumentEndpoint('GET', pathSegments)

    const params = queryKey.find((key) => typeof key === 'object') ?? {}

    const url = utils.urlJoin(baseUrl(queryKey), pathSegments)
    const { data } = await axios.get(url, { params })
    return data
}

/*
███    ███ ██    ██ ████████  █████  ████████ ██  ██████  ███    ██
████  ████ ██    ██    ██    ██   ██    ██    ██ ██    ██ ████   ██
██ ████ ██ ██    ██    ██    ███████    ██    ██ ██    ██ ██ ██  ██
██  ██  ██ ██    ██    ██    ██   ██    ██    ██ ██    ██ ██  ██ ██
██      ██  ██████     ██    ██   ██    ██    ██  ██████  ██   ████
*/

const defaultMutationFn = async ({ verb, queryKey, data, axiosOptions = {} }) => {
    queryKey = cleanQueryKey(queryKey)

    // TODO: move conversion of queryKey to a utils function and make more robust:
    var pathSegments = queryKey.filter(
        (key) => typeof key !== 'object' && key !== 'valuation' && key !== 'auth'
    )
    pathSegments = patchDocumentEndpoint(verb, pathSegments)

    const lastKey = unref(queryKey[queryKey.length - 1])
    const params = typeof lastKey === 'object' ? utils.objectMap(lastKey, unref) : {}

    // DEBUG: simulate slow network:
    // await new Promise((resolve) => setTimeout(resolve, 5000))

    const { data: returnedData } = await axios({
        method: verb,
        url: utils.urlJoin(baseUrl(queryKey), pathSegments),
        params,
        data,
        ...axiosOptions,
    })

    return returnedData
}

const defaultOnSuccess = (data, { verb, queryKey, refreshQueryKey, forceRefetch }) => {
    refreshQueryKey = refreshQueryKey ? cleanQueryKey(refreshQueryKey) : getBaseQueryKey(queryKey)

    if (forceRefetch) {
        return queryClient.refetchQueries({ queryKey: refreshQueryKey, type: 'active' })
    } else if (['PATCH', 'PUT'].includes(verb)) {
        // TODO: do we want a deepmerge instead of a shallow merge?
        queryClient.setQueriesData({ queryKey: refreshQueryKey }, (cachedData) => ({
            ...cachedData,
            ...data,
        }))
    }
}

/*
 ██████  ██████  ████████ ██ ███    ███ ██ ███████ ████████ ██  ██████
██    ██ ██   ██    ██    ██ ████  ████ ██ ██         ██    ██ ██
██    ██ ██████     ██    ██ ██ ████ ██ ██ ███████    ██    ██ ██
██    ██ ██         ██    ██ ██  ██  ██ ██      ██    ██    ██ ██
 ██████  ██         ██    ██ ██      ██ ██ ███████    ██    ██  ██████
*/

// Basic optimistic update of cache (only for PUT and PATCH)
const optimisticOnMutate = async ({ verb, queryKey, data, mergeOptions }) => {
    if (['PATCH', 'PUT'].includes(verb)) {
        // console.debug('🧟 optimisticOnMutate', queryKey, verb, data)

        await queryClient.cancelQueries({ queryKey, exact: true })
        queryClient.setQueriesData({ queryKey, exact: true }, (cachedData) => {
            if (!cachedData) return
            return verb == 'PUT' ? data : deepmerge(cachedData, data, mergeOptions)
        })

        // const debug = queryClient.getQueryData(queryKey)`
        // console.warn('🧟 DEBUG optimisticOnMutate', debug, verb)
    }
}

const optimisticOnError = (error, { verb, queryKey }, _context) => {
    // TODO: use context to handle rollback of optimistic update
    // Rollback optimistic update:
    if (['PATCH', 'PUT'].includes(verb)) {
        queryKey = cleanQueryKey(queryKey)

        // TODO: move handling of details out of here
        // Handling the raw queryKey and the {details: full} variant:
        const queryKeys = [queryKey, getBaseQueryKey(queryKey, verb)]
        if (queryKey[1] == 'request') {
            queryKeys.push([...queryKey, { details: 'full' }])
        }

        queryKeys.map(async (q) => {
            queryClient.invalidateQueries({ queryKey: q })
        })
    }
}

/*
 ██████ ██████  ██    ██ ██████
██      ██   ██ ██    ██ ██   ██
██      ██████  ██    ██ ██   ██
██      ██   ██ ██    ██ ██   ██
 ██████ ██   ██  ██████  ██████
*/

// full optimistic CRUD mutation that assumes:
// - GET prefix/resource -> list
// - POST prefix/resource creates a new item and returns -> list
// - GET prefix/resource/:id -> single item
// - PUT/PATCH prefix/resource/:id -> single item
// - DELETE prefix/resource/:id -> NO_CONTENT
const crudOnMutate = async ({ verb, queryKey, data, mergeOptions, idFieldName = 'id' }) => {
    // dconsole.log(`🧟 crud Mutation onMutate ${verb} | ${hashKey(listQueryKey)}}`)
    queryKey = cleanQueryKey(queryKey)
    const context = {}
    const listQueryKey = getListQueryKey(queryKey, verb)

    // For PATCH/PUT
    const baseQueryKey = getBaseQueryKey(queryKey, verb)
    optimisticOnMutate({ verb, queryKey: baseQueryKey, data, mergeOptions })

    // Update list endpoint
    await queryClient.cancelQueries({ queryKey: listQueryKey, exact: true })
    if (verb == 'POST') {
        // Insert query with a transient id
        // console.debug('🧟 crudOnMutate POST', queryKey, data, listQueryKey)
        const tempId = `temp-${uuidv4()}`
        context.tempId = tempId
        queryClient.setQueryData(listQueryKey, (cachedData) => [
            ...(cachedData || []),
            { [idFieldName]: tempId, ...data, tempClient: true },
        ])
    } else if (verb == 'DELETE') {
        queryClient.setQueryData(listQueryKey, (cachedData) => {
            return (cachedData || []).filter((item) => item[idFieldName] !== queryKey.at(-1))
        })
    } else {
        const baseQueryKey = getBaseQueryKey(queryKey, verb)

        // PUT/PATCH
        queryClient.setQueriesData({ queryKey: listQueryKey, exact: true }, (cachedData) => {
            return (cachedData || []).map((item) => {
                if (item[idFieldName] === baseQueryKey.at(-1)) {
                    return verb == 'PUT' ? data : deepmerge(item, data, mergeOptions)
                }
                return item
            })
        })
    }

    return context
}

const crudOnError = (error, { verb, queryKey }, context) => {
    if (['PATCH', 'PUT'].includes(verb)) {
        optimisticOnError(error, { verb, queryKey: getBaseQueryKey(queryKey) }, context)
    }

    queryClient.invalidateQueries({ queryKey: getListQueryKey(queryKey), exact: true })
}

const crudOnSuccess = (data, { verb, queryKey, forceRefetch, mergeOptions, idFieldName = 'id' }, context) => {
    // Individual entry cache update:
    defaultOnSuccess(data, { verb, queryKey, forceRefetch })

    // List cache update:
    const listQueryKey = getListQueryKey(queryKey, verb)
    if (['PATCH', 'PUT'].includes(verb)) {
        if (forceRefetch) {
            return queryClient.refetchQueries({ queryKey: listQueryKey, type: 'active' })
        } else {
            queryClient.setQueryData(listQueryKey, (cachedData) => {
                if (!cachedData) return
                return cachedData.map((item) => {
                    if (item[idFieldName] && item[idFieldName] === data[idFieldName]) {
                        return verb == 'PUT' ? data : deepmerge(item, data, mergeOptions)
                    }
                    return item
                })
            })
        }
    } else if (verb == 'POST' && context.tempId) {
        queryClient.setQueryData(listQueryKey, (cachedData) => {
            // FIXME: handle case where POST returns an array with multiple added items (eg. documents)

            // If POST returns an array, we assume it contains only the new item
            if (Array.isArray(data) && data.length > 0) data = data[0]

            if (!cachedData) return
            // Replace temp item with data returned by POST
            return cachedData.map((item) => {
                if (item[idFieldName] === context.tempId) {
                    return data
                }
                return item
            })
        })
    }
}

/*
 ██████  ███████ ███████ ██      ██ ███    ██ ███████
██    ██ ██      ██      ██      ██ ████   ██ ██
██    ██ █████   █████   ██      ██ ██ ██  ██ █████
██    ██ ██      ██      ██      ██ ██  ██ ██ ██
 ██████  ██      ██      ███████ ██ ██   ████ ███████
*/

const isTransientError = (error) => {
    return error?.response?.status === undefined || error?.response?.status == 502
}

const offlineMutationFn = async ({ verb, queryKey, data, axiosOptions = {} }) => {
    // NOTE: unless mutation is declared with networkMode: 'always', defaultMutationFn will
    //  not even be called in offline mode, but instead auto-retried later
    if (!onlineManager.isOnline()) {
        dconsole.log('🧟 defaultMutationFn: offline mode')
        throw new Error('Offline')
    }

    return defaultMutationFn({ verb, queryKey, data, axiosOptions })
}

const offlineMutationOnMutate = async ({ verb, queryKey, data, mergeOptions }) => {
    queryKey = cleanQueryKey(queryKey)

    // For PATCH/PUT (valuation requests)
    if (['PATCH', 'PUT'].includes(verb)) {
        optimisticOnMutate({ verb, queryKey, data, mergeOptions })
        if (queryKey[2] == 'request' && queryKey.length == 4) {
            optimisticOnMutate({
                verb,
                queryKey: [...queryKey, { details: 'full' }],
                data,
                mergeOptions,
            })
        }

        // NOTE: in local storage we need to update:
        // 1. the mutation itself (create/update)
        // 2. the GET query
        // 3. the GET query with full details (in case of request)

        var storageKey = getMutationStorageKey({ queryKey, verb })
        // NOTE: use updateItem instead of get/setItem to avoid race conditions
        storage.updateItem(storageKey, (storedData) => {
            if (storedData) storedData = JSON.parse(storedData)
            if (!storedData?.state) {
                storedData = {
                    mutationKey: cleanQueryKey(queryKey),
                    data: {},
                    verb: verb,
                }
            }
            storedData.data = deepmerge(storedData.data, data, mergeOptions)
            return JSON.stringify(storedData)
        })

        // TODO: improve this in the backend or automatically check for the existence of variants
        const queryKeys =
            queryKey[2] == 'request' ? [queryKey, [...queryKey, { details: 'full' }]] : [queryKey]
        // NOTE: the following ensures that persisted data is updated (not just the cache)
        // so that we can reload multiple times (while offline) and have the latest data
        // TODO: see if we can instead make the persister update from cache (might need to fake a succesful mutation)
        // TODO: we might need to tweak other aspects of the persister's data (such as dates):
        queryKeys.map(async (storageKey) => {
            storageKey = makePersisterStorageKey(storageKey)

            // NOTE: use updateItem instead of get/setItem to avoid race conditions
            storage.updateItem(storageKey, (storedData) => {
                if (!storedData) return
                storedData = JSON.parse(storedData)
                if (!storedData.state?.data) return

                storedData.state.data = deepmerge(storedData.state.data, data, mergeOptions)
                return JSON.stringify(storedData)
                // return storedData
            })
        })
    } else if (['POST', 'DELETE'].includes(verb)) {
        // For POST/DELETE (documents)

        // TODO: store in local storage
        // var storageKey = makeStorageKey(verb, queryKey)
        // storage.setItem(storageKey, JSON.stringify({ mutationKey: queryKey, data, verb }))

        // NOTE: we need to pass on the context for the onSuccess function (hence return):
        return crudOnMutate({
            verb,
            queryKey,
            data,
            mergeOptions,
            idFieldName: 'document_ref',
        })
    }
}

const offlineMutationOnSettled = async (_result, error, { verb, queryKey, data: _ }, _context) => {
    queryKey = cleanQueryKey(queryKey)

    dconsole.log(
        `🧟 offline-enabled Mutation onSettled: ${hashKey(queryKey)} | Error: ${
            error?.response?.status
        } - ${error}`
    )

    // Return (without removing local storage) if:
    // - we get an HTTP error indicating a transient server issue (502?)
    // - we are offline (no HTTP error)
    if (error && isTransientError(error)) return

    // Clean up: remove stored mutation from local storage
    queryKey = cleanQueryKey(queryKey)

    dconsole.log(
        `🧟 offline-enabled Mutation onSettled cleaning local storage for | ${verb} | ${hashKey(queryKey)}`
    )
    storage.removeItem(getMutationStorageKey({ queryKey, verb }))
    dconsole.log(`🧟 offline-enabled Mutation onSettled: refetching queries ${hashKey(queryKey)}`)

    // NOTE: now handling GET cache update in onSuccess:
}

const offlineMutationOnError = (error, { verb, queryKey, data: _ }, _context) => {
    queryKey = cleanQueryKey(queryKey)

    dconsole.log(
        `🧟 offline-enabled Mutation onError | ${verb} | ${hashKey(queryKey)} (should be offline): ${error}`
    )

    // Do NOT rollback optimistic update if we are offline
    if (isTransientError(error)) return

    if (['PATCH', 'PUT'].includes(verb)) {
        // Clean up optimistic update of valuation requests:
        optimisticOnError(error, { verb, queryKey })
    } else if (verb == 'POST') {
        // Clean up optimistic update of documents:
        crudOnError(error, { verb, queryKey })
    }
    // TODO: do we need this (possibly redundant with above):
    return queryClient.refetchQueries({ queryKey: queryKey, type: 'active' })
    // TODO: do we need to explicitly clean the persisted storage or will the above take care of it?
}

const offlineMutationOnSuccess = (
    data,
    { verb, queryKey, forceRefetch, mergeOptions, idFieldName },
    context
) => {
    // For POST (documents): update the cache with the returned data:
    if (verb == 'POST') {
        // document POST will return a list instead of an object, unpack it
        if (data.length > 0) data = data[0]
        // remove params from queryKey (if any) to get the list queryKey
        const listQueryKey = getListQueryKey(queryKey, verb)

        // TODO: handle multiple documents in a single POST?
        crudOnSuccess(
            data,
            {
                verb,
                queryKey: listQueryKey,
                forceRefetch,
                mergeOptions,
                idFieldName: idFieldName ?? 'document_ref',
            },
            context
        )
    } else if (['PATCH', 'PUT'].includes(verb)) {
        // console.debug('🧟 offlineMutationOnSuccess', verb, queryKey, forceRefetch)
        if (forceRefetch) {
            return queryClient.refetchQueries({ queryKey, type: 'active' })
        }
    }
}

const storeMutationsOnMutate = async ({ verb: _verb, queryKey, data: _data }) => {
    queryKey = cleanQueryKey(queryKey)

    // TODO: do we want to optimistically update the cache (again)?
    // -> call optimisticOnMutate?

    await queryClient.cancelQueries({ queryKey })
}

/*
 ██████  ██    ██ ███████ ██████  ██    ██  ██████ ██      ██ ███████ ███    ██ ████████
██    ██ ██    ██ ██      ██   ██  ██  ██  ██      ██      ██ ██      ████   ██    ██
██    ██ ██    ██ █████   ██████    ████   ██      ██      ██ █████   ██ ██  ██    ██
██ ▄▄ ██ ██    ██ ██      ██   ██    ██    ██      ██      ██ ██      ██  ██ ██    ██
 ██████   ██████  ███████ ██   ██    ██     ██████ ███████ ██ ███████ ██   ████    ██
    ▀▀
*/

const persister = experimental_createPersister({ storage })
const queryClient = new QueryClient()

queryClient.setDefaultOptions({
    queries: {
        queryFn: defaultQueryFn,
        cacheTime: 1000 * 60 * 60 * 24 * 2, // 2 days
        persister,
    },
    mutations: {
        // Some of these defaults will be overriden for `offline` and `submitStoreMutations` mutations
        mutationFn: defaultMutationFn,
        // onMutate: function() {},
        // onSettled: (_result, _error, { verb, queryKey, data: _ }, _context) => {
        //     dconsole.log(`🧟 default Mutation onSettled | ${verb} | ${hashKey(queryKey)}`)
        // },
        onSuccess: defaultOnSuccess,
    },
})

queryClient.setMutationDefaults([OPTIMISTIC_MUTATION_PREFIX], {
    // Basic mutation that does optimistic update

    // TODO: do we need to handle the key in a specific way?
    onMutate: optimisticOnMutate,
    onError: optimisticOnError,
})

queryClient.setMutationDefaults([CRUD_MUTATION_PREFIX], {
    // mutation that does optimistic updates assuming a standardised CRUD endpoint
    // mutationFn: function() {},
    onMutate: crudOnMutate,
    onError: crudOnError,
    onSuccess: crudOnSuccess,
})

queryClient.setMutationDefaults([OFFLINE_MUTATION_PREFIX], {
    // Default options when attempting a mutation that should work offline

    mutationFn: offlineMutationFn,
    onMutate: offlineMutationOnMutate,
    onError: offlineMutationOnError,
    onSettled: offlineMutationOnSettled,
    onSuccess: offlineMutationOnSuccess,

    // NOTE: We need mutations to be attempted (and fail) even in offline mode
    // to avoid having them queued up until online (default behaviour):
    networkMode: 'always',
})

// // NOTE: for now, we handle documents as a special case of offline mutations
// // TODO: merge this with offline mutations
// queryClient.setMutationDefaults([DOCUMENT_MUTATION_PREFIX], {
//     // Default options when attempting a mutation that should work offline

//     mutationFn: offlineMutationFn,
//     onMutate: offlineMutationOnMutate,
//     onError: offlineMutationOnError,
//     onSettled: offlineMutationOnSettled,

//     networkMode: 'always',
// })

queryClient.setMutationDefaults([SUBMIT_STORE_MUTATION_PREFIX], {
    // Default options when submitting previously-stored mutations:

    onMutate: storeMutationsOnMutate,
    onError: offlineMutationOnError,
    onSettled: offlineMutationOnSettled,
})

// this needs to be called with a mutateAsync function from useMutation
// upon first load or when online status changes
async function submitStoredMutations(mutateAsync) {
    const prefix = getMutationStorageKey()

    const keys = await storage.getKeys().then((ks) => ks.filter((k) => k.startsWith(prefix)))
    if (keys.length === 0) {
        dconsole.log('🧟 submitStoredMutations: No mutations to submit')
        return
    }

    const storedMutations = await getMany(keys)
    dconsole.log(
        `🧟 submitStoredMutations found ${keys.length} keys, retrieved ${storedMutations.length} stored mutations`
    )

    storedMutations.forEach(async (storedMutation) => {
        const { mutationKey, verb, data } = JSON.parse(storedMutation)
        // TODO:discard if TOO OLD
        // TODO: do we want to stop related GET queries?
        dconsole.log(`🧟 submitStoredMutations: submitting ${hashKey(mutationKey)}`)
        await mutateAsync({ verb, queryKey: mutationKey, data })
    })
}

export {
    cleanQueryKey,
    defaultMutationFn,
    submitStoredMutations,
    queryClient,
    CRUD_MUTATION_PREFIX,
    OPTIMISTIC_MUTATION_PREFIX,
    OFFLINE_MUTATION_PREFIX,
    SUBMIT_STORE_MUTATION_PREFIX,
}
