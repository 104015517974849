<template>
    <template v-if="props.isHorizontal">
        <template v-if="props.alwaysShowHorizontal || !props.flow.currentStep.hidden">
            <template v-if="$q.screen.gt.sm && props.flow.totalVisibleSteps < BREAK_POINT">
                <!-- TODO: rewrite with own CSS -->
                <q-stepper
                    :model-value="props.flow.currentStep?.rootName"
                    flat
                    header-class="text-bold"
                    active-icon="mdi-pencil-outline"
                    done-color="primary"
                    inactive-color="secondary"
                >
                    <q-step
                        v-for="(step, i) in props.flow.visibleSteps"
                        :key="`step_${i}`"
                        :name="step.name"
                        :prefix="step.visibleStepIdx + 1"
                        :title="step.label"
                        :done="step.done"
                        :icon="step.icon"
                        class="hidden"
                    />
                </q-stepper>
                <q-separator />
            </template>
            <div v-else>
                <div class="row items-center q-pa-md sticky-top">
                    <template v-if="props.flow.totalVisibleSteps >= BREAK_POINT">
                        <q-avatar
                            v-if="props.flow.currentStep.icon"
                            color="primary-light"
                            class="text-primary q-mr-sm"
                            size="md"
                            :icon="props.flow.currentStep.icon"
                        />
                        <p class="q-my-auto text-body2">
                            <strong>{{ props.flow.currentStep.label }}</strong>
                        </p>
                        <q-badge class="q-ml-auto re-mr text-body1" color="info">
                            <strong>
                                {{ props.flow.currentStep.visibleStepIdx + 1 }} /
                                {{ props.flow.totalVisibleSteps }}
                            </strong>
                        </q-badge>
                    </template>
                    <template v-for="(step, i) in props.flow.visibleSteps" v-else :key="i">
                        <re-icon
                            v-if="step.done"
                            name="mdi-check"
                            class="q-pa-sm bg-primary-bground rounded-borders q-mr-sm"
                            color="primary"
                        />
                        <div v-else-if="step.active" class="row items-center col-grow">
                            <div
                                class="step text-primary bg-primary-bground rounded-borders row items-center justify-center"
                            >
                                <strong>
                                    {{ i + 1 }}
                                </strong>
                            </div>
                            <strong class="text-primary q-ml-sm">
                                {{ props.flow.currentStep.label }}
                            </strong>
                        </div>
                        <div
                            v-else
                            class="step text-secondary bg-secondary-bground rounded-borders row items-center justify-center q-ml-sm"
                        >
                            <strong>{{ i + 1 }}</strong>
                        </div>
                    </template>
                </div>
                <q-separator />
            </div>
        </template>
    </template>
    <q-list v-else v-bind="$attrs" class="stepper">
        <slot name="steps-prepend" />
        <q-item
            v-for="(step, i) in props.flow.visibleSteps"
            :key="`step_${i}`"
            :active="step.active"
            :class="{ 'done-step': step.done }"
        >
            <q-item-section avatar>
                <q-avatar size="xl" :icon="step.done ? 'mdi-check' : step.icon">
                    <template v-if="!step.done && !step.icon">{{ step.visibleStepIdx + 1 }}</template>
                </q-avatar>
            </q-item-section>
            <q-item-section>
                <q-item-label class="header">
                    {{ step.label }}
                </q-item-label>
                <q-item-label v-if="step.caption" caption>
                    {{ step.caption }}
                </q-item-label>
            </q-item-section>
        </q-item>
        <slot name="steps-append" />
    </q-list>
</template>
<script setup>
const props = defineProps({
    flow: {
        type: Object,
        required: true,
    },
    isHorizontal: {
        type: Boolean,
        default: false,
    },
    alwaysShowHorizontal: {
        type: Boolean,
        default: false,
    },
})

const BREAK_POINT = 5
</script>
<style lang="scss" scoped>
.stepper {
    margin-top: 80px;

    .q-item {
        .q-avatar {
            background-color: $blue-grey-1;
            color: $secondary;
        }
        &.done-step {
            .q-avatar {
                background-color: $positive-bground;
                color: $positive;
            }
        }
        &.q-router-link--active {
            .q-item__label.header {
                font-weight: bold;
            }
            .q-avatar {
                background-color: $blue-1;
                color: $primary;
            }
        }
    }
}
.step {
    width: 30px;
    height: 30px;
}

:deep(.q-stepper__header--standard-labels) {
    .q-stepper__tab {
        min-height: 56px; // force it to be same height as the stepper
    }
}

:deep(.q-stepper__tab .q-stepper__dot) {
    border-radius: 8px;
    color: $secondary;
    background-color: $secondary-bground;

    span {
        color: $secondary;
    }
}

:deep(.q-stepper__tab--done .q-stepper__dot),
:deep(.q-stepper__tab--active .q-stepper__dot) {
    fill: $primary;
    color: $primary;
    background-color: $primary-light;

    :before {
        color: $primary;
    }
}
</style>
