<template>
    <div v-if="!isLoading" class="accrs_list">
        <re-tooltip
            v-for="(accr, i) in accrs"
            :key="user + '_accr_' + i"
            :label="accr.accreditation_number || 'No number'"
        >
            <q-badge type="primary" :closable="editable" @close="showDelete(accr)">
                {{ accr.institution }}
            </q-badge>
        </re-tooltip>
        <q-badge v-if="editable" type="is-primary is-light" class="add_accr-bttn" @click="showNewAccr">
            +
        </q-badge>
        <re-dialog v-model="modalShown">
            <div v-if="isDeleting" class="accr-modal">
                <h3>Are you sure you want to delete this accreditation number?</h3>
                <p>This action cannot be reverted.</p>
                <div class="modal-actions">
                    <re-button color="negative" label="Delete" @click="removeAccr" />
                    <re-button label="Cancel" @click="cancelModal" />
                </div>
            </div>
            <div v-if="isCreating" class="accr-modal">
                <h3>{{ $t('admin_panel.user_mgmt.new_accreditation') }}</h3>
                <re-input
                    v-model="newAccrInstitution"
                    type="text"
                    :placeholder="$t('admin_panel.user_mgmt.accreditation_institution')"
                />
                <re-input
                    v-model="newAccrNr"
                    type="text"
                    :placeholder="$t('admin_panel.user_mgmt.accreditation_number')"
                />
            </div>
            <template #actions>
                <re-button
                    color="main"
                    :disable="isNewAccrDisabled"
                    :label="$t('admin_panel.user_mgmt.add_accreditation')"
                    @click="createNewAccr"
                />
                <re-button label="Cancel" @click="cancelModal" />
            </template>
        </re-dialog>
    </div>
    <q-skeleton v-else width="100%" height="100%" />
</template>
<script>
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios.js'
export default {
    name: 'AdminAccreditations',
    props: {
        user: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isLoading: true,
        accrs: null,
        newAccrInstitution: '',
        newAccrNr: '',
        modalShown: false,
        isCreating: false,
        isDeleting: false,
        deletingAccr: null,
    }),
    computed: {
        isNewAccrDisabled() {
            return utils.isEmptyStr(this.newAccrInstitution)
        },
    },
    watch: {
        isCreating(val) {
            this.newAccrInstitution = ''
            this.newAccrNr = ''
            this.modalShown = val
        },
        isDeleting(val) {
            this.modalShown = val
        },
    },
    mounted() {
        this.loadAccrs()
    },
    methods: {
        loadAccrs() {
            if (!this.user) {
                this.isLoading = false
                return
            }

            let url = utils.urlJoin(this.$config.AUTH_API_URL, ['accreditations', 'user', this.user])

            axios
                .get(url)
                .then((res) => {
                    this.accrs = res.data
                    this.isLoading = false
                })
                .catch(() => {
                    this.isLoading = false
                    return
                })
        },
        cancelModal() {
            this.isCreating = false
            this.isDeleting = false
        },
        showNewAccr() {
            this.isCreating = true
        },
        showDelete(accr) {
            this.deletingAccr = accr
            this.isDeleting = true
        },
        removeAccr() {
            this.isLoading = true
            let url = utils.urlJoin(this.$config.AUTH_API_URL, ['accreditations', this.deletingAccr.id])

            axios.delete(url).then(() => {
                this.loadAccrs()
                this.isDeleting = false
                this.deletingAccr = null
            })
        },
        resetNewAccr() {
            this.newAccrInstitution = ''
            this.newAccrNr = ''
        },
        createNewAccr() {
            let url = utils.urlJoin(this.$config.AUTH_API_URL, ['accreditations', 'user', this.user])

            axios
                .post(url, {
                    institution: this.newAccrInstitution,
                    accreditation_number: this.newAccrNr,
                })
                .then(() => {
                    this.loadAccrs()
                    this.isCreating = false
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.accr-modal {
    padding: 1rem;
    background: white;
    z-index: 99;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    box-shadow: 0px 2px 14px 0px #002f501a;
    min-width: 250px;
    max-width: 400px;

    h3 {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
    }

    .modal-actions {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-top: 1rem;
    }
}

.accrs_list {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.3rem;
}

.add_accr-tooltip {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 150px;
}
</style>
