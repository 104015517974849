<template>
    <q-btn-dropdown
        v-if="menuOptions.length > 1"
        no-caps
        size="sm"
        unelevated
        outline
        class="re-sm re-main"
        style="border: red"
    >
        <q-list dense class="q-py-sm">
            <q-item v-for="m in menuOptions" :key="m" v-close-popup clickable :to="pathTo(m)">
                <q-item-section avatar class="avatar">
                    <q-avatar round size="sm">
                        <re-svg :name="m" subdir="request-types" />
                    </q-avatar>
                </q-item-section>
                <q-item-section>
                    <q-item-label class="text-secondary text-bold">
                        {{ m === 'dvm' ? $t(`value.short.valuation`) : $t(`value.short.${m}`) }}
                    </q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
    </q-btn-dropdown>
    <re-button
        v-else-if="menuOptions.length === 1"
        :to="pathTo(curUser.submitableModules[0])"
        :label="$attrs.label ?? $t(`value.short.${curUser.submitableModules[0]}`)"
        size="md"
        icon="mdi-plus"
        color="main"
        v-bind="$attrs"
    >
        <slot />
    </re-button>
</template>

<script setup>
import { inject, computed } from 'vue'
import _ from 'lodash'

const curUser = inject('curUser')

const menuOptions = computed(() =>
    _.uniq(curUser.submitableModules.map((module) => (module === 'ovm' ? 'dvm' : module)))
)

const pathTo = (m) => {
    if (m === 'ers') {
        return {
            name: 'ers.welcome',
            // NOTE: we could abstain from setting query.resetCurRequest here and rely on route.meta
            query: { resetCurRequest: true },
        }
    } else if (m === 'oepc') {
        return {
            name: 'oepc.request',
            params: { action: 'welcome' },
            query: { resetCurRequest: true },
        }
    } else {
        return {
            name: 'valuation.request',
            // TODO: split routes by action and move query.resetCurRequest to route.meta
            query: { valuation_type: m, resetCurRequest: true },
            params: { action: 'start' },
        }
    }
}
</script>
<style scoped lang="scss">
.avatar {
    min-width: auto;
}

// TODO: make a dropdown version of ReButton and use it here
.q-btn-dropdown {
    height: 2.858em;
    min-height: 2.858em;
    line-height: 1em;

    :deep(.q-icon) {
        height: 0.5em;
    }

    &.re-main {
        background-color: $accent !important;
        // NOTE: we need to this to same as background to avoid problems with the border colour. Text is overriden with the next rule
        color: $accent !important;
        :deep(.q-btn__content) {
            color: white;
        }
    }
}
</style>
