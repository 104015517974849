import * as Sentry from '@sentry/vue'

import VueClipboard from 'vue3-clipboard'
import { createApp, reactive, getCurrentInstance } from 'vue'

import App from '@/App.vue'
import { createHead } from '@unhead/vue'
// import './registerServiceWorker'
import fetchConfig from '@/shared/plugins/config'
import { i18n } from '@/i18n'
import setupRouter from '@/router'
import store from '@/store/index'
import { VueQueryPlugin, onlineManager } from '@tanstack/vue-query'
import { queryClient } from '@/composables/query'
import ReLibrary from '@/shared/plugins/reLibrary'
import ReFormat from '@/shared/plugins/format'

import { Actions } from './store/Log/index.js'
import { Mutations } from './store/index.js'

// Leaflet stuff
import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: import('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: import('leaflet/dist/images/marker-icon.png'),
    shadowUrl: import('leaflet/dist/images/marker-shadow.png'),
})

// Quasar
import { Quasar, Notify } from 'quasar'
import quasarIconSet from 'quasar/icon-set/mdi-v7'

// TODO: which icon libs do we need?
import '@quasar/extras/mdi-v7/mdi-v7.css'
// import '@quasar/extras/material-icons/material-icons.css'
// import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'

import '@quasar/extras/animate/slideInRight.css'
import '@quasar/extras/animate/slideInLeft.css'
import '@quasar/extras/animate/slideInDown.css'

import '@quasar/extras/animate/fadeIn.css'
import '@quasar/extras/animate/fadeOut.css'

import '@quasar/extras/animate/tada.css'

// import '@quasar/extras/animate/fadeInLeft.css'
// import '@quasar/extras/animate/fadeOutRight.css'
// import '@quasar/extras/animate/fadeInUp.css'
// import '@quasar/extras/animate/fadeOutDown.css'
// import '@quasar/extras/animate/fadeOut.css'

import 'quasar/src/css/index.sass'

// Error catching:
if (import.meta.env.MODE === 'production') {
    // TODO: improve traceback display and re-enable
    window.addEventListener('unhandledrejection', function (event) {
        // NOTE: this will only work if devServer.client.overlay is also set to false:
        event.preventDefault()

        if (event.reason?.handled_by_axios) return

        try {
            const error = {
                error_code: 'unhandled_promise_rejection',
                debug: {
                    message: `Unhandled promise rejection: ${event.reason.message}`,
                    traceback: event.reason.stack,
                },
            }
            store.dispatch(Actions.LOG_ERROR, { error, errorType: 'error' })
        } catch (e) {
            // Prevent infinite loop:
            console.log('[Fallback from unhandledrejection handler. Exception]', e)
        }
    })
}

// NOTE: replaced by quasar:
// import '@mdi/font/css/materialdesignicons.css'
// // import Buefy from 'buefy'

// // TODO: replace svgicon by Vue3-compatible option
// // 'svgicon'
// // import SvgIcon from 'vue-svgicon'
// // import '@/shared/assets/svg-icon'
// // Vue.use(SvgIcon, {
// //     tagName: 'svg-icon',
// //     defaultWidth: '1em',
// //     defaultHeight: '1em',
// //     isOriginalDefault: true,
// // })

// Create a global head instance
const head = createHead()

let app

async function initialize() {
    await fetchConfig().then((config) => {
        if (config.VALUATION_API_URL === undefined) {
            throw new Error('Empty config')
        }
        config.LOCALE_OVERWRITE = config.LOCALE_OVERWRITE.toLowerCase()

        // NOTE: disables Plausible globally:
        localStorage.plausible_ignore = !config.ENABLE_PLAUSIBLE
        app = createApp(App)

        // TODO: pick a lane for config:
        const rconfig = reactive(config)
        app.config.globalProperties.$config = rconfig
        app.provide('config', rconfig)
        store.commit(Mutations.SET_CONFIG, config)

        app.config.productionTip = false
        app.config.warnHandler = function (msg, vm, trace) {
            // Do not handle if not in env mode:
            if (import.meta.env.MODE !== 'development') {
                console.warn('Vue warning:', msg, import.meta.env.MODE)
                return
            }
            try {
                const warning = {
                    error_code: 'vue_warning',
                    debug: {
                        message: msg,
                        traceback: trace,
                    },
                }
                store.dispatch(Actions.LOG_ERROR, { error: warning, errorType: 'warning' })
            } catch (e) {
                // Prevent infinite loop:
                console.log('[Fallback from global warning handler. Exception]', e)
            }
        }
        // app.config.errorHandler = function (err, vm, info) {
        // try {
        //     const error = {
        //         error_code: 'vue_error',
        //         debug: {
        //             message: `Error in ${info} : ${err.message}`,
        //             traceback: err.stack,
        //             context: {
        //                 info: info,
        //                 // TODO: extract more info from vm.$options (avoid recursion)
        //                 // vue_vm_options: vm.$options
        //                 vm_options_name: vm?.$options ? vm.$options.name : null,
        //                 // vm_options_propsData: vm.$options
        //                 //     ? JSON.stringify(vm.$options.propsData)
        //                 //     : null,
        //             },
        //         },
        //     }
        //     store.dispatch(Actions.LOG_ERROR, { error, errorType: 'error' })
        // } catch (e) {
        //     // Prevent infinite loop:
        //     console.log('[Fallback from global error handler. Exception]', e)
        // }
        // }

        app.use(head)
        app.use(i18n)
        app.use(store)
        app.use(VueQueryPlugin, { queryClient, enableDevtoolsV6Plugin: true })
        // Import RE Library components (ReButton, TitleWithTooltip…)
        app.use(ReLibrary)
        app.use(VueClipboard)
        app.use(ReFormat, { locale: i18n.global.locale })
        app.provide('format', app.config.globalProperties.$format)
        app.use(Quasar, {
            plugins: {
                // import Quasar plugins and add here
                Notify,
            },
            iconSet: quasarIconSet,
            config: {
                screen: { bodyClasses: true },
                // animations: ['all'], // doesn't seem to work (need CSS imports)
                // brand: {
                // // primary: '#e46262',
                // // ... or all other brand colors
                // },
                // notify: {...}, // default set of options for Notify Quasar plugin
                // loading: {...}, // default set of options for Loading Quasar plugin
                // loadingBar: { ... }, // settings for LoadingBar Quasar plugin
                // // ..and many more (check Installation card on each Quasar component/directive/plugin)
            },
        })

        // // Always start with an online state that is coherent with the source of the config
        // // This is useful because, when our PWA is loaded from cache, the browser reports being online, even though it isn't, and tanstack/query also believes that.
        onlineManager.setOnline(config.source === 'online')

        const router = setupRouter(config)

        if (config.SENTRY_DSN && import.meta.env.MODE === 'production') {
            console.log('📡 Sentry enabled', config.SENTRY_DSN)
            Sentry.init({
                app,
                dsn: config.SENTRY_DSN,
                integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
                environment: import.meta.env.MODE,
                tracesSampleRate: 1.0,
                // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
                ignoreErrors: ['ResizeObserver loop limit exceeded'],

                // NOTE: our version of Sentry does not support replay-event atm:
                // Capture Replay for 1% of all sessions,
                // plus for 100% of sessions with an error
                // replaysSessionSampleRate: 0.01,
                // replaysOnErrorSampleRate: 1.0,
            })
        }

        app.use(router)

        // TODO: replace by Vue3 compatible option:
        // import * as VueGoogleMaps from 'vue2-google-maps'
        // if (config.GOOGLE_MAP_KEY !== 'disabled') {
        //     if (config.GOOGLE_MAP_KEY !== '') {
        //         // app.use(VueGoogleMaps, {
        //         //     load: {
        //         //         key: config.GOOGLE_MAP_KEY,
        //         //         libraries: 'places',
        //         //     },
        //         // })
        //     } else {
        //         console.warn('Missing Google map key from server settings.')
        //     }
        // }

        // TODO: replace above with composable:
        // import Vuelidate from 'vuelidate'
        // ->
        // import { useVuelidate } from '@vuelidate/core'
        // import { required, email } from '@vuelidate/validators'

        // TODO: VUE3 replace by either:
        //  @unhead/vue
        // OR:
        // https://quasar.dev/quasar-plugins/meta
        // import VueMeta from 'vue-meta'

        // get access to store in testing
        if (window.Cypress) {
            // only available during E2E tests
            window.app = app
        }

        app.mount('#app')
    })
}
initialize()

export const useGlobals = () => {
    // in env mode, switch to debugger:
    if (!app && !getCurrentInstance() && import.meta.env.MODE === 'development') {
        debugger
    }
    if (app) return app.config.globalProperties
    return getCurrentInstance().appContext.config.globalProperties
}
